import 'react-tooltip/dist/react-tooltip.css';

import { AnimatePresence, motion } from 'framer-motion';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { Tooltip } from 'react-tooltip';

import { languageNames } from '../../intl/config';
import { SectionContext } from '../SectionContext/SectionContext';
import styles from './NavBar.module.scss';

const NavBar = (props) => {
  const router = useRouter();
  console.log('dataNavigationData', props);
  const { locale } = router;
  const [langDropdown, setLangDropdown] = useState(false);
  const languageNameRef = useRef(null);
  const [openLanguage, setOpenLanguage] = useState(false);

  const t = props.t;
  const [dashboardURLLogin, setDashboardURLLogin] = useState('https://app.whiteclover.uk/dashboard/login');
  const [dashboardURLRegister, setDashboardURLRegister] = useState(
    'https://app.whiteclover.uk/dashboard/create-account',
  );

  const [isOpen, setIsOpen] = useState(false);

  const menuList = [
    {
      id: 'offer-section',
      text: `${t.navbar1}`,
      link: '/#offer-section',
    },
    {
      id: 'wedding-planner',
      text: `${t.navbar2}`,
      link: '/#wedding-planner',
    },
    {
      id: 'guest-app',
      text: `${t.navbar3}`,
      link: '/#guest-app',
    },
    // {
    //   text: `${t.navbar4}`,
    //   link: '/#your-kind-of-story',
    // },
    {
      id: 'pricing',
      text: `${t.navbar5}`,
      link: '/pricing',
    },
    {
      id: 'blog',
      text: `${t.navbar6}`,
      link: '/blog',
    },
    {
      text: `${t.navbar7}`,
      link: '/partners',
    },
  ];

  const clickHandler = () => {
    if (isOpen) {
      setIsOpen(false);
      const x = document.getElementsByTagName('BODY')[0] as HTMLElement;
      x.style.overflow = 'scroll';
    } else {
      setIsOpen(true);
      const x = document.getElementsByTagName('BODY')[0] as HTMLElement;
      x.style.overflow = 'hidden';
    }
  };

  useEffect(() => {
    setDashboardURLLogin(
      window.location.host.includes('localhost')
        ? 'http://localhost:3000/dashboard/login'
        : `https://app.${window.location.host}/dashboard/login`,
    );

    setDashboardURLRegister(
      window.location.host.includes('localhost')
        ? 'http://localhost:3000/dashboard/create-account'
        : `https://app.${window.location.host}/dashboard/create-account`,
    );
  }, []);

  const { activeSection, setActiveSection } = useContext(SectionContext);

  useEffect(() => {
    switch (activeSection) {
      case 'home-section':
        console.log('home-section caught');
        break;
      default:
        console.log(`default: ${activeSection} caught`);
        break;
    }
  }, [activeSection]);

  useEffect(() => {
    if (router.pathname === '/') {
      const hash = router.asPath.split('#')[1];

      if (hash === 'home-section') {
        setActiveSection('home-section');
      } else if (hash === 'offer-section') {
        setActiveSection('offer-section');
      } else {
        setActiveSection('home-section');
      }
    } else if (router.pathname === '/pricing') {
      setActiveSection('pricing');
    } else if (router.pathname === '/blog') {
      setActiveSection('blog');
    }
  }, [router.asPath]);

  return (
    <div style={{ overflow: 'hidden' }}>
      <nav className={styles.navBar} style={isOpen ? { background: 'transparent' } : {}}>
        <a href="/#home-section">
          <img
            src={`${process.env.CDN_PROVIDER_URL}/newassets/Logo.svg`}
            alt="Logo"
            className={styles.logo}
            style={{ opacity: !isOpen ? 1 : 0 }}
          />
        </a>
        <ul className={styles.desktopList}>
          <Tooltip id={`desktopMenuItem${menuList.length - 1}`} />
          {menuList.map((element, index) => (
            <li key={`desktopMenuItem${index}`}>
              <Link scroll={true} href={element.link}>
                <p className={element.id === activeSection ? `didacticP ${styles.activeP}` : 'didacticP'}>
                  {element.text}
                </p>
              </Link>
            </li>
          ))}
          <li className={styles.loginLi}>
            <Link href={dashboardURLLogin} className={styles.login}>
              <p className={'didacticP'}>{t.navbar8}</p>
            </Link>
          </li>
          <li className={styles.signUpLi}>
            <Link href={dashboardURLRegister} className={styles.createwedding}>
              <p className={'didacticP'}>{t.navbar9}</p>
            </Link>
          </li>
          <li className={styles.dropdown + ' ' + styles.dropdownHover}>
            <div>
              {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
              <Link
                href=""
                style={{
                  marginRight: '8px',
                  display: 'flex',
                }}
                className={styles.navLinks}
                onClick={() => setLangDropdown(!langDropdown)}
              >
                <div className={styles.dropdownBtn}>
                  {/*{languageShort[locale]}{' '}*/}
                  <img
                    loading="lazy"
                    src={`${process.env.CDN_PROVIDER_URL}/assets/icons/icon-language.svg`}
                    alt="arrow"
                    width={20}
                    height={20}
                  />
                </div>
              </Link>
              <div className={styles.dropdownContentLang + ' ' + (langDropdown ? styles.dropdownContentLangShow : '')}>
                <p
                  className={'didacticP'}
                  onClick={() => {
                    router.push(router.pathname, router.asPath, { locale: 'en' });
                    setLangDropdown(false);
                  }}
                >
                  {languageNames['en']}
                </p>
                <p
                  className={'didacticP'}
                  onClick={() => {
                    router.push(router.pathname, router.asPath, { locale: 'el' });
                    setLangDropdown(false);
                  }}
                >
                  {languageNames['el']}
                </p>
              </div>
            </div>
          </li>
        </ul>
        <div className={isOpen ? `${styles.burger} ${styles.burgerOpen}` : `${styles.burger}`} onClick={clickHandler}>
          <div className={isOpen ? `${styles.xOpen}` : `${styles.x}`}></div>
          <div className={isOpen ? `${styles.yOpen}` : `${styles.y}`}></div>
          <div className={isOpen ? `${styles.zOpen}` : `${styles.z}`}></div>
        </div>
      </nav>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{
              display: isOpen ? 'flex' : 'none',
              backgroundColor: '#63435B',
              position: 'fixed',
              top: '0px',
              right: '0px',
              width: '0px',
              height: '0px',
              borderBottomLeftRadius: '100%',
              zIndex: 100,
              overflow: 'scroll',
              maxHeight: '110vh',
            }}
            animate={{
              width: 'calc(100vh + 100vw)',
              height: 'calc(100vh + 100vw)',
              transition: { duration: 0.5, ease: 'easeInOut' },
            }}
            exit={{
              width: '0px',
              height: '0px',
              transition: { duration: 0.5, ease: 'easeInOut' },
            }}
          >
            <motion.ul
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.3, ease: 'easeInOut' } }}
              exit={{ opacity: 0 }}
              className={`${styles.list} ${styles.mobileList}`}
            >
              <li>
                <Link href="/#home-section" onClick={clickHandler}>
                  <img
                    loading="lazy"
                    src={`${process.env.CDN_PROVIDER_URL}/newassets/emblem_logo_white.svg`}
                    alt="logo white"
                  />
                </Link>
              </li>
              <Tooltip id={`mobileMenuItem${menuList.length - 1}`} />
              {menuList.map((element, index) => (
                <li key={`mobileMenuItem${index}`}>
                  <Link scroll={true} href={element.link} onClick={clickHandler}>
                    {element.text}
                  </Link>
                </li>
              ))}
              <li className={styles.loginLi}>
                <Link href={dashboardURLLogin} className={styles.login}>
                  {t.navbar8}
                </Link>
              </li>
              <li>
                <Link href={dashboardURLRegister} className={styles.createwedding}>
                  {t.navbar9}
                </Link>
              </li>
              <li>
                <div>
                  <div
                    ref={languageNameRef}
                    className={`${styles.langButton} ${'didacticH4'}`}
                    onClick={() => {
                      setOpenLanguage((prev) => !prev);
                    }}
                  >
                    <img
                      loading="lazy"
                      src={`${process.env.CDN_PROVIDER_URL}newassets/icons/language.svg`}
                      alt={'language-change'}
                    />
                    <div>{languageNames[locale]}</div>
                  </div>
                  {openLanguage && (
                    <div
                      style={{
                        top: `${languageNameRef?.current?.offsetTop - 200}px`,
                        left: languageNameRef?.current?.getBoundingClientRect().left,
                      }}
                      className={styles.languagesList}
                    >
                      <p
                        onClick={() => {
                          router.push(router.pathname, router.asPath, { locale: 'en' });
                          setOpenLanguage(false);
                          clickHandler();
                        }}
                      >
                        {languageNames['en']}
                      </p>
                      <p
                        onClick={() => {
                          router.push(router.pathname, router.asPath, { locale: 'el' });
                          setOpenLanguage(false);
                          clickHandler();
                        }}
                      >
                        {languageNames['el']}
                      </p>
                    </div>
                  )}
                </div>
              </li>
            </motion.ul>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
export default NavBar;
